define("discourse/plugins/discourse-news-section/discourse/models/news-article", ["exports", "discourse/models/rest", "discourse/models/topic", "discourse/models/user", "@ember/object"], function (_exports, _rest, _topic, _user, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NewsArticle extends _rest.default {
    isSaving = false;
    deleted = false;
    comments = [];
    noComments = false;
    commentsVisible = false;

    // Native getter for `isNews`
    get isNews() {
      return this.type === 'news';
    }

    // Native getter for `isTalk`
    get isTalk() {
      return this.type === 'talk';
    }
    get shareUrl() {
      if (this.id) {
        return `/news/${this.id}`;
      }
      return null;
    }
    constructor() {
      super(...arguments);
      this.setupInitialValues();
    }
    setupInitialValues() {
      this.isSaving = false;
      this.deleted = false;
      this.comments = [];
      if (this.published_at) {
        const date = new Date(this.published_at.replace(" ", "T"));
        const mins = date.getMinutes().toString().padStart(2, '0');
        const hrs = date.getHours().toString().padStart(2, '0');
        this.published_at_time = `${hrs}:${mins}`;
      }
      if (this.topic_posts_count) {
        this.topic_posts_count = parseInt(this.topic_posts_count) - 1;
      }
      if (_user.default.current()) {
        const currentUser = _user.default.current();
        this.canEdit = this.user_id === currentUser.id || currentUser.admin;
        this.canDelete = this.user_id === currentUser.id || currentUser.admin;
      }
    }
    updateFromJson(postStreamData) {
      let posts = this.comments;
      if (posts.length > 0) {
        this.comments = [];
      } else if (postStreamData) {
        const startIdx = postStreamData.posts.length - 4 < 1 ? 1 : postStreamData.posts.length - 4;
        for (let i = startIdx; i < postStreamData.posts.length; i++) {
          posts.push(postStreamData.posts[i]);
        }
        this.noComments = posts.length === 0;
        delete postStreamData.posts;
        this.comments = posts;
      }
    }
    updateWithNewPost(post) {
      this.noComments = false;
      this.topic_posts_count = parseInt(this.topic_posts_count) + 1;
      document.querySelector(`#comments_count_post_${this.id}`).innerHTML = `${this.topic_posts_count} ${I18n.lookup('news.comments')}`;
      document.querySelector(`#comments_count_post_${this.id}`).parentNode.classList.remove('hidden');
      this.comments.push(post);
    }
    async reload(data) {
      const topic = this.topic;
      const opts = {
        nearPost: data.post_number
      };
      try {
        const json = await (0, _topic.loadTopicView)(topic, opts);
        this.topic = topic;
        const lastPost = json.post_stream.posts[json.post_stream.posts.length - 1];
        this.updateWithNewPost(lastPost);
        (0, _object.setProperties)(this, {
          loadingFilter: false,
          timelineLookup: json.timeline_lookup,
          loaded: true
        });
        delete json.post_stream.posts;
      } catch (result) {
        this.errorLoading(result);
        throw result;
      }
    }
    toggleCommentsVisibility() {
      this.commentsVisible = !this.commentsVisible;
    }
    static #_ = (() => dt7948.n(this.prototype, "toggleCommentsVisibility", [_object.action]))();
    async showComments(post) {
      this.commentsVisible = !this.commentsVisible;
      if (post.topic_id) {
        const params = {
          id: post.topic_id
        };
        let topic = this.store.createRecord('topic', params);
        const opts = {
          nearPost: post.topic_posts_count
        };
        this.subscribe(topic);
        try {
          const json = await (0, _topic.loadTopicView)(topic, opts);
          post.topic = topic;
          this.updateFromJson(json.post_stream);
          (0, _object.setProperties)(this, {
            loadingFilter: false,
            timelineLookup: json.timeline_lookup,
            loaded: true
          });
        } catch (result) {
          this.errorLoading(result);
          throw result;
        }
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "showComments", [_object.action]))();
    subscribe(topic) {
      this.unsubscribe(topic);
      const messageBus = Discourse.__container__.lookup('message-bus:main');
      messageBus.subscribe(`/topic/${topic.id}`, data => {
        const postStream = topic.postStream;
        switch (data.type) {
          case "created":
            this.reload(data);
            break;
          default:
            console.warn("Unknown topic bus message type", data);
        }
        if (data.reload_topic) {
          topic.reload().then(() => {
            this.send('postChangedRoute', topic.post_number || 1);
          });
        }
      });
    }
    unsubscribe(topic) {
      const topicId = topic.id;
      if (!topicId) return;
      const messageBus = Discourse.__container__.lookup('message-bus:main');
      messageBus.unsubscribe(`/topic/${topicId}`);
    }
  }
  _exports.default = NewsArticle;
});