define("discourse/plugins/discourse-news-section/discourse/controllers/news-new", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object"], function (_exports, _controller, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NewsNewController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "saved", [_tracking.tracked], function () {
      return false;
    }))();
    #saved = (() => (dt7948.i(this, "saved"), void 0))(); // By default we haven't saved anything
    static #_2 = (() => dt7948.g(this.prototype, "lastValidatedAt", [_tracking.tracked], function () {
      return null;
    }))();
    #lastValidatedAt = (() => (dt7948.i(this, "lastValidatedAt"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "model", [_tracking.tracked]))();
    #model = (() => (dt7948.i(this, "model"), void 0))(); // Using tracked for the model data
    // Getter for saveButtonText computed from model.isSaving
    get saveButtonText() {
      return this.model.isSaving ? I18n.t('saving') : I18n.t('save');
    }

    // Getter for titleValidation computed from model.title and lastValidatedAt
    get titleValidation() {
      const title = this.model.get("title");
      if (this.model.titleMissing && !title) {
        return _object.default.create({
          failed: true,
          reason: I18n.t('news.error.title_missing'),
          lastShownAt: this.lastValidatedAt
        });
      }
    }

    // Getter for contentValidation computed from model.reply and lastValidatedAt
    get contentValidation() {
      const reply = this.model.get("reply");
      if (this.model.contentMissing && !reply) {
        return _object.default.create({
          failed: true,
          reason: I18n.t('news.error.content_missing'),
          lastShownAt: this.lastValidatedAt
        });
      }
    }

    // Getter for publishedAtValidation computed from model.published_at and lastValidatedAt
    get publishedAtValidation() {
      const published_at = this.model.get("published_at");
      if (this.model.publishedAtMissing && !published_at) {
        return _object.default.create({
          failed: true,
          reason: I18n.t('news.error.published_at_missing'),
          lastShownAt: this.lastValidatedAt
        });
      }
    }

    // Getter for timezoneOffset
    get timezoneOffset() {
      const date = new Date().toString();
      return date.substring(date.indexOf("GMT") + 3, date.indexOf("GMT") + 6);
    }

    // Action to handle news saving
    validateAndSave() {
      let save = true;
      if (!this.model.title) {
        this.model.titleMissing = true;
        this.model.notifyPropertyChange('title');
        save = false;
      }
      if (!this.model.reply) {
        this.model.contentMissing = true;
        this.model.notifyPropertyChange('reply');
        save = false;
      }
      if (!this.model.published_at) {
        this.model.publishedAtMissing = true;
        this.model.notifyPropertyChange('published_at');
        save = false;
      }
      if (save) {
        // Trigger the route's save action
        this.send('saveNews');
      }
      return save;
    }
    static #_4 = (() => dt7948.n(this.prototype, "validateAndSave", [_object.action]))();
  }
  _exports.default = NewsNewController;
});