define("discourse/plugins/discourse-news-section/discourse/components/date-time-picker", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{date-picker value=this.model.published_at id="published-at-date"}}
  {{input type="time" name="published-at-time" id="published-at-time" class="published-at-time" value=this.model.published_at_time}}
  <span class="timezone-offset">UTC{{this.timezoneOffset}}</span>
  {{input-tip validation=this.validation id="published-at-validation"}}
  
  */
  {
    "id": "wSp8MkNu",
    "block": "[[[1,[28,[35,0],null,[[\"value\",\"id\"],[[30,0,[\"model\",\"published_at\"]],\"published-at-date\"]]]],[1,\"\\n\"],[1,[28,[35,1],null,[[\"type\",\"name\",\"id\",\"class\",\"value\"],[\"time\",\"published-at-time\",\"published-at-time\",\"published-at-time\",[30,0,[\"model\",\"published_at_time\"]]]]]],[1,\"\\n\"],[10,1],[14,0,\"timezone-offset\"],[12],[1,\"UTC\"],[1,[30,0,[\"timezoneOffset\"]]],[13],[1,\"\\n\"],[1,[28,[35,3],null,[[\"validation\",\"id\"],[[30,0,[\"validation\"]],\"published-at-validation\"]]]],[1,\"\\n\"]],[],false,[\"date-picker\",\"input\",\"span\",\"input-tip\"]]",
    "moduleName": "discourse/plugins/discourse-news-section/discourse/components/date-time-picker.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    timezoneOffset() {
      var date = new Date().toString();
      return date.substring(date.indexOf("GMT") + 3, date.indexOf("GMT") + 6);
    },
    // @computed('time')
    // timeWithOffset(time) {
    //   var date = new Date(this.get('date'));
    //   var mins = (date.getMinutes() + "").length == 1 ? "0" + (date.getMinutes() + "") : (date.getMinutes() + "");
    //   var hrs = (date.getHours() + "").length == 1 ? "0" + (date.getHours() + "") : (date.getHours() + "");
    //   this.set('time', hrs + ":" + mins);
    //   return this.get('time');
    // },

    didInsertElement() {
      if (this.get('model').published_at !== undefined) {
        var datePickerDate = moment(this.get('model').published_at).format("YYYY-MM-DD");
        $('#published-at-date').find("input").val(datePickerDate);
      }
    }
  }, [["method", "timezoneOffset", [(0, _decorators.default)()]]])));
});