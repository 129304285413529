define("discourse/plugins/discourse-news-section/discourse/routes/news-new", ["exports", "discourse/plugins/discourse-news-section/discourse/models/news-article", "discourse/models/user", "@ember/routing/route", "discourse/lib/ajax", "discourse-common/lib/get-owner"], function (_exports, _newsArticle, _user, _route, _ajax, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    setupController: function (controller, model) {
      controller.set('model', model);
      this._super(controller, model);
    },
    beforeModel(transition) {
      if (!_user.default.current() || !_user.default.current().get('admin')) {
        const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
        router.transitionTo('news.index');
      }
    },
    model(params, transition) {
      return _newsArticle.default.create();
    },
    actions: {
      saveNews: function () {
        var self = this;
        var model = this.controller.model;
        var dateDigits = model.published_at.split("-");
        var date = new Date(dateDigits[0], dateDigits[1] - 1, dateDigits[2]);
        if (model.published_at_time) {
          var timeDigits = model.published_at_time.split(":");
          date = new Date(dateDigits[0], dateDigits[1] - 1, dateDigits[2], timeDigits[0], timeDigits[1]);
        }
        model.set('published_at_json', date.toJSON());
        model.set('isSaving', true);
        (0, _ajax.ajax)({
          url: "/news/new",
          type: "POST",
          data: {
            user_id: _user.default.current().id,
            image_file_name: model.get('image_file_name'),
            label: model.get('label'),
            reply: model.get('reply'),
            sticky: model.get('sticky'),
            title: model.get('title'),
            visible: model.get('visible'),
            published_at: model.get('published_at_json')
          }
        }).then(function (result) {
          model.set('isSaving', false);
          const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
          router.transitionTo('news.index');
        }).catch(function (result) {
          console.log(result);
        });
      }
    }
  });
});