define("discourse/plugins/discourse-news-section/discourse/components/latest-user-content", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="latest-user-content">
    <span><stronger>Latest user content</stronger></span>
    <hr>
    <div class="user-content-feed">
      {{#each this.model.content as |post index|}}
        <a href="/media/{{post.id}}">
          <div class="activity">
            <div class="activity-title">
              <strong>{{post.title}}</strong>
            </div>
            <div class="activity-category">
              {{format-age post.created_at}} {{i18n 'news.ago'}} {{i18n 'news.by'}} <strong>{{post.user.username}}</strong>
            </div>
          </div>
        </a>
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "WSHmQBxx",
    "block": "[[[10,0],[14,0,\"latest-user-content\"],[12],[1,\"\\n  \"],[10,1],[12],[10,\"stronger\"],[12],[1,\"Latest user content\"],[13],[13],[1,\"\\n  \"],[10,\"hr\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"user-content-feed\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"model\",\"content\"]]],null]],null],null,[[[1,\"      \"],[10,3],[15,6,[29,[\"/media/\",[30,1,[\"id\"]]]]],[12],[1,\"\\n        \"],[10,0],[14,0,\"activity\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"activity-title\"],[12],[1,\"\\n            \"],[10,\"strong\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"activity-category\"],[12],[1,\"\\n            \"],[1,[28,[35,8],[[30,1,[\"created_at\"]]],null]],[1,\" \"],[1,[28,[35,9],[\"news.ago\"],null]],[1,\" \"],[1,[28,[35,9],[\"news.by\"],null]],[1,\" \"],[10,\"strong\"],[12],[1,[30,1,[\"user\",\"username\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"post\",\"index\"],false,[\"div\",\"span\",\"stronger\",\"hr\",\"each\",\"-track-array\",\"a\",\"strong\",\"format-age\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-news-section/discourse/components/latest-user-content.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({}));
});