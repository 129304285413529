define("discourse/plugins/discourse-news-section/discourse/routes/news-show", ["exports", "discourse/routes/discourse", "discourse-common/lib/get-owner"], function (_exports, _discourse, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    setupController: function (controller, model) {
      controller.set('model', model);
      this._super(controller, model);
    },
    model(params, transition) {
      return this.store.find('news-article', params.id);
    },
    actions: {
      editPost(post) {
        const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
        router.transitionTo("news.edit", post);
      }
    }
  });
});