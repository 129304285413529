define("discourse/plugins/discourse-news-section/discourse/controllers/news-index", ["exports", "discourse/models/composer", "discourse/lib/plugin-api", "discourse/plugins/discourse-news-section/discourse/models/recent-forum-activity", "discourse/plugins/discourse-news-section/discourse/models/latest-user-content", "discourse/plugins/discourse-news-section/discourse/models/recent-talk", "@ember/controller", "@ember/owner", "discourse/components/modal/share-topic"], function (_exports, _composer, _pluginApi, _recentForumActivity, _latestUserContent, _recentTalk, _controller, _owner, _shareTopic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    needs: ['modal', 'composer', 'application'],
    //'quote-button',

    topicDelegated: ['visitTopic', 'replyToPost'],
    init() {
      this._super(arguments);
      this.set("recent_forum_activity", _recentForumActivity.default.create({
        context: this
      }));
      this.set("latest_user_content", _latestUserContent.default.create());
      this.set("recent_talk", _recentTalk.default.create());
    },
    loadingMore: true,
    offset: 0,
    limit: 10,
    sort_order: "asc",
    actions: {
      editPost(post) {
        this.get('target').send('editPost', post);
      },
      deletePost(post) {
        this.get('target').send('deletePost', post);
      },
      sharePost(post) {
        (0, _owner.getOwner)(this).lookup("service:modal").show(_shareTopic.default, {
          model: {
            topic: post
          }
        });
      },
      getMore: function () {
        if (this.get('loadingMore')) {
          return;
        }
        this.set('loadingMore', true);
        this.get('target').send('getMore');
      },
      gotMore: function () {
        this.set('loadingMore', false);
      },
      visitTopic(newsPost) {},
      replyToPost(post) {
        const composerController = (0, _owner.getOwner)(this).lookup("controller:composer");
        const topic = post.get('topic');
        const opts = {
          action: _composer.default.REPLY,
          topic: topic,
          draftKey: topic.get('draft_key'),
          draftSequence: topic.get('draft_sequence'),
          replyingToTopic: true
        };
        Discourse.User.currentProp('disable_jump_reply', true);
        composerController.open(opts);
        return false;
      },
      scheduleActivityCheck() {
        this.recent_forum_activity.startProcess(this);
      },
      unScheduleActivityCheck() {
        this.recent_forum_activity.preventProcess(this);
      }
    }
  });
});