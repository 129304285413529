// import DS from 'ember-data';
//
// export default DS.Store.extend({
//   findRecord() {
//
//   },
//   createRecord() {
//
//   },
//   updateRecord() {
//
//   },
//   deleteRecord(store, type, post) {
//     return new Ember.RSVP.Promise(function(resolve, reject) {
//       Ember.$.ajax({
//         url: "/news/" + post.id,
//         type: "DELETE",
//         accepts: "application/json"
//       }).then(result => {
//         Ember.run(null, resolve, result);
//       }, jqXHR => {
//         jqXHR.then = null; // tame jQuery's ill mannered promises
//         Ember.run(null, reject, jqXHR);
//       });
//     });
//   },
//   findAll() {
//
//   },
//   query() {
//
//   }
// });
define("discourse/plugins/discourse-news-section/discourse/services/store", [], function () {
  "use strict";
});