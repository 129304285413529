define("discourse/plugins/discourse-news-section/discourse/routes/news-index", ["exports", "discourse/routes/discourse", "@ember/owner"], function (_exports, _discourse, _owner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    path: "news",
    init: function () {
      var self = this;
      // News.reopen({
      //   didGetMore: function() {
      //     if (self.controller) {
      //       self.get('controller').send('gotMore');
      //     }
      //   }
      // });
    },
    setupController(controller, model) {
      controller.set('model', model);
      controller.send('gotMore');
      this._super(controller, model);
      controller.setProperties({
        model,
        editingTopic: false
      });
    },
    model(params, transition) {
      return this.store.findAll('news-article', {
        offset: "0",
        limit: "10"
      });
    },
    actions: {
      replyToPost(post) {
        this.controller.actions.replyToPost(post);
      },
      getMore() {
        var self = this;
        var limit = this.controller.get('limit'),
          offset = this.controller.get('offset');
        this.controller.set('offset', offset + limit);
        this.store.findAll('news-article', {
          offset: this.controller.get('offset'),
          limit: this.controller.get('limit')
        }).then(function (result) {
          self.controller.model.addObjects(result.content);
          self.controller.send('gotMore');
        });
      },
      editPost(post) {
        const router = (0, _owner.getOwner)(this).lookup("service:router");
        router.transitionTo("news.edit", post);
      },
      deletePost(post) {
        var model = this.controller.get('model');
        this.store.destroyRecord('news-article', post).then(function (result) {
          if (result.success) {
            for (var i = 0; i < model.content.length; i++) {
              if (post.id == model.content[i].id) {
                model.content[i].set('deleted', true);
                setTimeout(function () {
                  $(".media .posts .row.deleted").height('0');
                }, 100);
                setTimeout(function () {
                  model.content.removeObject(model.content[i]);
                }, 500);
                break;
              }
            }
          }
        }).catch(function (result) {
          console.log(result);
        });
      }
    }
  });
});