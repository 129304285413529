define("discourse/plugins/discourse-news-section/discourse/models/recent-talk", ["exports", "discourse/lib/ajax", "@ember/object"], function (_exports, _ajax, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend({
    talk: [],
    init() {
      this.fetchLatestTalk();
    },
    fetchLatestTalk() {
      var self = this;
      (0, _ajax.ajax)("/talk/latest", {
        type: "GET"
      }).then(function (result) {
        self.set("talk", result.talk_article);
      });
    }
  });
});