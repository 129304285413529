define("discourse/plugins/discourse-news-section/discourse/components/recent-forum-activity", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="recent-forum-activity">
    <span><stronger>Recent forum activity</stronger></span>
    <hr>
    <div class="forum-feed">
      {{#each this.model.topics as |topic index|}}
        {{component 'news-topic-post' post=topic}}
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "U5pfy5iu",
    "block": "[[[10,0],[14,0,\"recent-forum-activity\"],[12],[1,\"\\n  \"],[10,1],[12],[10,\"stronger\"],[12],[1,\"Recent forum activity\"],[13],[13],[1,\"\\n  \"],[10,\"hr\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"forum-feed\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"model\",\"topics\"]]],null]],null],null,[[[1,\"      \"],[46,\"news-topic-post\",null,[[\"post\"],[[30,1]]],null],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"topic\",\"index\"],false,[\"div\",\"span\",\"stronger\",\"hr\",\"each\",\"-track-array\",\"component\"]]",
    "moduleName": "discourse/plugins/discourse-news-section/discourse/components/recent-forum-activity.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    init() {
      this._super();
      //this.get("model").startProcess(this);
    }
    // didInsertElement() {
    //   if (this.siteSettings.recent_activity_live_update_enabled) {
    //     this.actions.scheduleActivityCheck();
    //   }
    // },
    // willDestroyElement() {
    //   if (this.siteSettings.recent_activity_live_update_enabled) {
    //     this.actions.unScheduleActivityCheck();
    //   }
    // },
    // actions: {
    //   scheduleActivityCheck() {
    //     this.get("model").startProcess(this);
    //   },
    //   unScheduleActivityCheck() {
    //     this.model.preventProcess(this);
    //   }
    // }
  }));
});