define("discourse/plugins/discourse-news-section/discourse/models/topic", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend({
    removed: false,
    new: false
  });
});