define("discourse/plugins/discourse-news-section/discourse/news-section-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route('news', {
      path: '/news'
    }, function () {
      this.route('new', {
        path: '/new'
      });
      this.route('show', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/edit/:id'
      });
      //this.route('lazy_categories', { path: '/lazy_categories'});
    });
  }
});