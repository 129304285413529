define("discourse/plugins/discourse-news-section/discourse/components/news-feed", ["exports", "discourse/mixins/scrolling", "@ember/component"], function (_exports, _scrolling, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_scrolling.default, {
    init() {
      this._super(...arguments);
    },
    didInsertElement() {
      this._readjustScrollPosition();
      this.bindScrolling();
      if (this.siteSettings.recent_activity_live_update_enabled) {
        this.scheduleActivityCheck();
      }
    },
    willDestroyElement() {
      $(window).scrollTop(0);
      this.unbindScrolling();
      this.unScheduleActivityCheck();
    },
    _readjustScrollPosition() {
      const scrollTo = this.session.get('newsListScrollPosition');
      if (scrollTo && scrollTo >= 0) {
        Ember.run.schedule('afterRender', () => $(window).scrollTop(scrollTo + 1));
      } else {
        //Ember.run.scheduleOnce('afterRender', this, this.loadMoreUnlessFull);
      }
    },
    saveScrollPosition() {
      this.session.set('newsListScrollPosition', $(window).scrollTop());
    },
    scrolled() {
      this.saveScrollPosition();
      if (this.isScrolledToBottom()) {
        this.getMore();
      }
    },
    isScrolledToBottom() {
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 50 && $(document).height() > $(window).height()) {
        return true;
      }
      return false;
    }
  });
});