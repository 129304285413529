define("discourse/plugins/discourse-news-section/discourse/components/news-topic-post", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a href="/t/{{this.post.slug}}">
    <div class="activity {{if this.post.removed 'removed'}} {{if this.post.new 'bumped'}} {{if this.post.same_as_previous 'same'}}">
      <div class="activity-title">
        <strong>{{this.post.title}}</strong>
      </div>
      <div class="activity-category">
        {{format-age this.post.last_posted_at}} {{i18n 'news.ago'}}
        {{i18n 'news.in'}} <strong>{{this.post.category_name}}</strong>
        {{i18n 'news.by'}} <strong>{{this.post.last_poster_username}}</strong>
      </div>
    </div>
  </a>
  
  */
  {
    "id": "4aJ6ngup",
    "block": "[[[10,3],[15,6,[29,[\"/t/\",[30,0,[\"post\",\"slug\"]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"activity \",[52,[30,0,[\"post\",\"removed\"]],\"removed\"],\" \",[52,[30,0,[\"post\",\"new\"]],\"bumped\"],\" \",[52,[30,0,[\"post\",\"same_as_previous\"]],\"same\"]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"activity-title\"],[12],[1,\"\\n      \"],[10,\"strong\"],[12],[1,[30,0,[\"post\",\"title\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"activity-category\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[[30,0,[\"post\",\"last_posted_at\"]]],null]],[1,\" \"],[1,[28,[35,5],[\"news.ago\"],null]],[1,\"\\n      \"],[1,[28,[35,5],[\"news.in\"],null]],[1,\" \"],[10,\"strong\"],[12],[1,[30,0,[\"post\",\"category_name\"]]],[13],[1,\"\\n      \"],[1,[28,[35,5],[\"news.by\"],null]],[1,\" \"],[10,\"strong\"],[12],[1,[30,0,[\"post\",\"last_poster_username\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"a\",\"div\",\"if\",\"strong\",\"format-age\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-news-section/discourse/components/news-topic-post.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    // templateName: 'news/topic-post',
    didInsertElement() {
      var topic = $(this.element).find('.activity');
      const topic_height = this.calculateTopicHeight(topic);
      if (topic.hasClass('bumped')) {
        var bumped = topic;
        var removed = $(".news .recent-forum-activity .activity.removed").first();
        var feed = $(".forum-feed");
        if (!topic.hasClass('same')) {
          const bumped_height = topic_height;
          const removed_height = removed.height();
          const feed_height = $(".forum-feed").height() - removed_height + bumped_height;
          removed.css('height', removed_height);
          removed.css('height', '0');
          bumped.css('height', bumped_height);
          feed.addClass("height-corrected");
          feed.css('height', feed_height);
        } else {
          removed.css('display', 'none');
          bumped.removeClass('bumped');
        }
        this.flash(bumped);
      } else {
        topic.css('height', topic_height);
      }
    },
    flash(bumped) {
      const originalCol = bumped.css('background-color');
      setTimeout(function () {
        bumped.addClass('highlighted').stop().animate({
          backgroundColor: originalCol
        }, 2500, 'swing', function () {
          bumped.removeClass('highlighted');
        });
      }, 300);
    },
    calculateFeedHeight() {
      var self = this;
      var feed_height = 0;
      $('.forum-feed').find('.activity').each(function (index, topic) {
        feed_height += self.calculateTopicHeight($(topic));
      });
      return feed_height;
    },
    calculateTopicHeight(topic) {
      const topic_height = topic.find('.activity-title').outerHeight() + topic.find('.activity-category').outerHeight() + parseInt(topic.find('.activity-category').css('margin-bottom').replace('px', ''));
      return topic_height;
    } //,
    // mouseEnter() {
    //   this.sendAction('unScheduleActivityCheck');
    // },
    // mouseLeave() {
    //   this.sendAction('scheduleActivityCheck');
    // }
  }));
});