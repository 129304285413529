define("discourse/plugins/discourse-news-section/discourse/models/recent-forum-activity", ["exports", "discourse/plugins/discourse-news-section/discourse/models/topic", "discourse/lib/ajax", "@ember/object"], function (_exports, _topic, _ajax, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RecentForumActivity = _object.default.extend({
    started: false,
    prevented: false,
    topics: [],
    categories: [],
    init() {
      this.fetch();
      this.startProcess(this.get("context"));
    },
    startProcess(context) {
      this.prevented = false;
      if (this.started) {
        return;
      }
      const tracker = this;
      const process = data => {
        if (!this.prevented) {
          tracker.fetchTopic(data.payload.topic_id).then(function (data) {
            tracker.placeTopicInList(data);
          });
        }
      };
      context.messageBus.subscribe("/latest", process);
      this.started = true;
    },
    preventProcess(context) {
      this.prevented = true;
      this.started = false;
      context.messageBus.unsubscribe("/latest");
    },
    fetch() {
      this.fetchCategories().then(() => {
        this.fetchRecentForumActivity();
      });
    },
    fetchCategories() {
      var model = this;
      return $.when((0, _ajax.ajax)("/news/lazy_categories", {
        type: "GET"
      })).then(function (result) {
        model.categories = {};
        result.categories.forEach(function (category) {
          model.categories[category.id] = category;
        });
      });
    },
    fetchRecentForumActivity() {
      var model = this;
      $.when($.ajax({
        url: "/latest.json",
        type: "GET"
      })).then(function (result) {
        for (var i = 0; i < 5 && i < result.topic_list.topics.length; i++) {
          var topic = result.topic_list.topics[i];
          if (!topic.pinned) {
            topic.category_name = model.categories[topic.category_id].name;
            var new_topic = _topic.default.create(topic);
            model.addObject(new_topic, function () {
              model.topics.addObject(new_topic);
            });
          }
        }
      });
    },
    placeTopicInList(data) {
      var self = this;
      var new_topic = _topic.default.create(data);
      new_topic.category_name = self.categories[data.category_id].name;
      new_topic.last_poster_username = data.details.last_poster.username;
      var old_topic = self.topics[4];
      self.topics.forEach(function (topic, index) {
        if (topic.id == new_topic.id) {
          old_topic = topic;
          if (index == 0) {
            Ember.set(new_topic, 'same_as_previous', true);
          }
          return false;
        }
      });
      self.removeObject(old_topic, function () {
        self.topics.removeObject(old_topic);
      });
      self.addObject(new_topic, function () {
        self.topics.unshiftObject(new_topic);
      });
      if (self.topics.size > 5) {
        self.topics = self.topics.splice(0, 4);
      }
    },
    removeObject(topic, callback) {
      topic.setProperties({
        removed: true,
        new: false
      });
      later(() => {
        callback();
      }, 2000);
    },
    addObject(topic, callback) {
      topic.setProperties({
        new: true
      });
      callback();
    },
    fetchTopic(id) {
      return $.ajax({
        url: "/t/" + id + ".json",
        type: "GET"
      });
    }
  });
  var _default = _exports.default = RecentForumActivity;
});