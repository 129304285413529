define("discourse/plugins/discourse-news-section/discourse/models/latest-user-content", ["exports", "discourse/lib/ajax-error", "@ember/object"], function (_exports, _ajaxError, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LatestUserContent = _object.default.extend({
    content: [],
    init() {
      this.fetch();
    },
    fetch() {
      var model = this;
      $.ajax({
        url: "/media",
        type: "GET",
        data: {
          offset: 0,
          limit: 4
        }
      }).then(function (result) {
        model.set("content", result.media_posts);
      });
    }
  });
  var _default = _exports.default = LatestUserContent;
});