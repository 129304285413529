define("discourse/plugins/discourse-news-section/discourse/components/news-comment", ["exports", "@ember/component", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{mount-widget widget="post"
                 args=post
                 model=post}}
  
  */
  {
    "id": "d31RUu1y",
    "block": "[[[1,[28,[35,0],null,[[\"widget\",\"args\",\"model\"],[\"post\",[30,0,[\"post\"]],[30,0,[\"post\"]]]]]],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `post` property path was used in the `discourse/plugins/discourse-news-section/discourse/components/news-comment.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.post}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `post` property path was used in the `discourse/plugins/discourse-news-section/discourse/components/news-comment.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.post}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"mount-widget\"]]",
    "moduleName": "discourse/plugins/discourse-news-section/discourse/components/news-comment.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  /*export default Ember.View.extend({
    templateName: 'news/news-comment',
    didInsertElement() {
      // console.log("inserted comment");
      // var highlight = this.highlight;
      // this.appEvents.on('post:highlight', postNumber => {
      //   Ember.run.scheduleOnce('afterRender', this, highlight, postNumber);
      // });
    },
    afterRenderEvent() {
      var highlight = this.highlight;
      this.appEvents.on('post:highlight', postNumber => {
        Ember.run.scheduleOnce('afterRender', this, highlight, postNumber);
      });
    },
    highlight(postNumber) {
      const $contents = $(`#post_${postNumber} .topic-body`),
            origColor = $contents.data('orig-color') || $contents.css('backgroundColor');
  
      $contents.data("orig-color", origColor)
        .addClass('highlighted')
        .stop()
        .animate({ backgroundColor: origColor }, 2500, 'swing', function() {
          $contents.removeClass('highlighted');
          $contents.css({'background-color': ''});
        });
    }
  });*/
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({}));
});