define("discourse/plugins/discourse-news-section/discourse/models/news", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend({
    news_articles: [],
    recent_forum_activity: [],
    latest_user_content: [],
    recent_talk: {},
    offset: 0,
    limit: 10,
    // sort_by: 'published_by',
    sort_order: 'desc',
    types: [],
    archetype: null,
    init: function () {
      this.set('news_articles', []);
      this.set('recent_forum_activity', []);
      this.set('latest_user_content', []);
      this.set('recent_talk', []);
      this.set('offset', 0);
      this.set('limit', 10);
      // this.set('sort_by', 'published_by');
      this.set('sort_order', 'desc');
      this.set('types', []);
      this.set('archetype', 'regular');
    },
    didGetMore: function () {
      //console.log("Did get more!");
    }
  });
});