define("discourse/plugins/discourse-news-section/discourse/templates/news/show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <article class="show-news">
  
    {{component 'news-post' post=this.model edit=(action 'editPost') delete=(action 'deletePost') share=(action 'sharePost')}}
  
  </article>
  
  */
  {
    "id": "pu6De9iA",
    "block": "[[[10,\"article\"],[14,0,\"show-news\"],[12],[1,\"\\n\\n  \"],[46,\"news-post\",null,[[\"post\",\"edit\",\"delete\",\"share\"],[[30,0,[\"model\"]],[28,[37,2],[[30,0],\"editPost\"],null],[28,[37,2],[[30,0],\"deletePost\"],null],[28,[37,2],[[30,0],\"sharePost\"],null]]],null],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[],false,[\"article\",\"component\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-news-section/discourse/templates/news/show.hbs",
    "isStrictMode": false
  });
});