define("discourse/plugins/discourse-news-section/discourse/adapters/news-article", ["exports", "discourse/adapters/rest", "discourse/lib/ajax", "@ember/runloop"], function (_exports, _rest, _ajax, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend({
    find(store, type, findArgs) {
      return (0, _ajax.ajax)(this.pathFor(store, 'new', findArgs)).catch(function (jqXHR) {
        console.log(jqXHR.responseText);
      });
    },
    createRecord() {},
    updateRecord() {},
    destroyRecord(store, type, post) {
      return new Promise(function (resolve, reject) {
        (0, _ajax.ajax)({
          url: "/news/" + post.id,
          type: "DELETE"
        }).then(result => {
          (0, _runloop.run)(null, resolve, result);
        }, jqXHR => {
          jqXHR.then = null; // tame jQuery's ill mannered promises
          (0, _runloop.run)(null, reject, jqXHR);
        });
      });
    },
    findAll(store, type, findArgs) {
      return (0, _ajax.ajax)(this.pathFor(store, 'new', findArgs)).catch(function (jqXHR) {
        console.log(jqXHR.responseText);
      });
    },
    query() {}
  });
});